import React from "react";
import {getKey} from "../Utils";

export default class TableHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        };
    }

    render() {
        let th = this.props.items.map(h => {
            return <th key={getKey()} scope="col" className={h.cssClassName}>{h.text}</th>;
        })
        return <tr key={getKey()}>
            {th}
        </tr>
    }
}