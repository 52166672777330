import React from "react";
import axios from "axios";
import {apiHost, getKey, getRegions} from "./Utils";
import ReactPaginate from "react-paginate";
import authHeader, {blobAuthHeader} from "./services/auth-header";
import DatePicker from "react-datepicker";
import {getMonth, getYear} from "date-fns";
import {Modal} from "react-responsive-modal";
import getTime from "date-fns/getTime";

const years = [2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
const months = ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentyar", "Oktyar", "Noyabr", "Dekabr"];

export default class DebtorsListView extends React.Component {

    constructor() {
        super();
        const currentTime = new Date();
        this.state = {
            showLoading: false,
            tableRows: [],
            category: null,
            group: '',
            start: 0,
            limit: 50,
            pageCount: 1,
            region: null,
            passportNumber: '',
            agreementNumber: '',
            startDate: new Date(currentTime.getFullYear(), 0, 1, 12, 0, 0),
            endDate: new Date(currentTime.getFullYear(), 11, 31, 12, 0, 0),
            filterModalOpen: false
        }
    }

    componentDidMount() {
        this.getData(this.state.category, this.state.region, this.state.start, this.state.limit);
    }

    loading(value) {
        this.setState({
            showLoading: value
        })
    }

    getData() {
        this.loading(true);
        axios.get(apiHost + '/api/listener/debtors/list?categoryId=' + (this.state.category !== null ? this.state.category : '') +
            '&regionId=' + (this.state.region !== null ? this.state.region : '') +
            '&startDate=' + getTime(this.state.startDate) + '&endDate=' + getTime(this.state.endDate) +
            '&passportNumber=' + this.state.passportNumber + '&agreementNumber=' + this.state.agreementNumber +
            '&start=' + this.state.start + '&limit=' + this.state.limit, authHeader())
            .then(result => {
                if (result.status === 200) {
                    let tableRows = result.data.data.map(d => {
                        return <tr key={getKey()}>
                            <td>{d.id}</td>
                            <td>{d.lastName + ' ' + d.firstName + ' ' + d.middleName}</td>
                            <td>{d.regionName}</td>
                            <td>{d.passportNumber}</td>
                            <td>{d.phoneNumber}</td>
                            <td>{d.organizationName}</td>
                            <td>{d.tendencyName}</td>
                            <td>{d.agreementNumber}</td>
                            <td>{d.agreementDate}</td>
                            <td>{d.allAmount.toLocaleString("ru-RU", {minimumFractionDigits: 0})}</td>
                            <td>{d.payedAmount.toLocaleString("ru-RU", {minimumFractionDigits: 0})}</td>
                            <td>{(d.allAmount - d.payedAmount).toLocaleString("ru-RU", {minimumFractionDigits: 0})}</td>
                        </tr>
                    });
                    this.setState({
                        tableRows: tableRows,
                        pageCount: result.data.pageCount
                    })
                }
                this.loading(false);
            })
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let start = selected === 0 ? 0 : selected * this.state.limit + 1;

        this.setState({
            start: start
        });
        this.getData(this.state.tendency, this.state.group, start, this.state.limit);
    };

    openCloseModal(value) {
        this.setState({
            filterModalOpen: value
        })
    }

    onChange(e) {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({
            [name]: value
        });
    }

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
    }

    exportToExcel() {
        let regionId = document.getElementById('region').value;
        let categoryId = document.getElementById('category').value;

        let data = new FormData();
        data.append("categoryId", Number(categoryId));
        data.append("regionId", Number(regionId));
        data.append("startDate", getTime(this.state.startDate));
        data.append("endDate", getTime(this.state.endDate));
        data.append("groupType", this.state.groupType);
        data.append("passportNumber", this.state.passportNumber);
        data.append("agreementNumber", this.state.agreementNumber);

        axios.post(apiHost + "/api/reports/debtor/list", data, blobAuthHeader())
            .then(result => {
                if (result.status === 200) {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(new Blob([result.data]));
                    link.setAttribute('download', 'Qarzdorlar.xlsx');

                    document.body.appendChild(link);

                    link.click();
                    link.parentNode.removeChild(link);
                }
            })
    }

    render() {
        return <div className="content">
            {this.state.showLoading &&
                <div className="container">
                    <div className="loader">
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--dot"/>
                        <div className="loader--text"/>
                    </div>
                </div>
            }
            <div className="card card-body shadow-sm">
                <div className="form-row">
                    <div className="col-md-1">
                        <button className="btn btn-info" onClick={this.openCloseModal.bind(this, true)}>
                            <i className="fa fa-filter"/>
                        </button>
                    </div>
                    <div className="col-md-11 text-center">
                        <h3>Qarzdorlar ro‘yxati</h3>
                    </div>
                </div>
                <div className="form-row pt-1">
                    <div className="col-md-12 order-md-1">
                        <table className="table table-striped table-bordered shadow">
                            <thead>
                            <tr>
                                <th style={{width: "5%"}}>№</th>
                                <th>F.I.Sh</th>
                                <th>Hudud</th>
                                <th>Pasport raqami</th>
                                <th>Telefon raqami</th>
                                <th>Tashkilot nomi</th>
                                <th>Yo‘nalishi</th>
                                <th>Shartnoma raqami</th>
                                <th>Shartnoma sanasi</th>
                                <th>Shartnoma summasi</th>
                                <th>To‘langan summa</th>
                                <th>Qarzi</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tableRows}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-12 order-md-1 text-center pt-2">
                        <ReactPaginate
                            previousLabel={'<<'}
                            nextLabel={'>>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={this.state.pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            onPageChange={this.handlePageClick}
                            containerClassName={'pagination'}
                            subContainerClassName={'pagination'}
                            activeClassName={'listing-page-item'}
                        />
                    </div>
                </div>
            </div>

            <Modal open={this.state.filterModalOpen} onClose={this.openCloseModal.bind(this, false)} center>
                <div className="container-md" style={{minHeight: "450px", marginTop: "2rem"}}>
                    <div className="d-flex">
                        <div className="col-md-6 mr-1">
                            <label htmlFor="category">Yo`nalishlar</label>
                            <select id="category" className="form-control" onChange={this.onChange.bind(this)}>
                                <option value="0">Hammasi</option>
                                <option value="2">Mediatorlik kurslari</option>
                                <option value="2">Qayta tayyorlash kurslari</option>
                                <option value="3">Malaka oshirish kurslari</option>
                                <option value="4">Qisqa muddatli kurslar</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="region">Hudud</label>
                            <select id="region" className="form-control" value={this.state.region}
                                    onChange={this.onChange.bind(this)}>
                                {getRegions()}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-md-6 mr-1">
                            <label className="col-form-label" htmlFor="startDate">Boshlanish sanasi</label>
                            <br/>
                            <DatePicker className="form-control" id="startDate" showIcon icon="fa fa-calendar"
                                        locale="uz" dateFormat="dd.MM.yyyy"
                                        renderCustomHeader={({
                                                                 date,
                                                                 changeYear,
                                                                 changeMonth,
                                                                 decreaseMonth,
                                                                 increaseMonth,
                                                                 prevMonthButtonDisabled,
                                                                 nextMonthButtonDisabled,
                                                             }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}>
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {"<"}
                                                </button>
                                                <select
                                                    value={getYear(date)}
                                                    onChange={({target: {value}}) => changeYear(value)}
                                                >
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[getMonth(date)]}
                                                    onChange={({target: {value}}) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                    {">"}
                                                </button>
                                            </div>
                                        )}
                                        selected={this.state.startDate} onChange={this.setStartDate.bind(this)}/>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label" htmlFor="endDate">Tugash sanasi</label>
                            <br/>
                            <DatePicker className="form-control" id="endDate" showIcon icon="fa fa-calendar" locale="uz"
                                        dateFormat="dd.MM.yyyy"
                                        renderCustomHeader={({
                                                                 date,
                                                                 changeYear,
                                                                 changeMonth,
                                                                 decreaseMonth,
                                                                 increaseMonth,
                                                                 prevMonthButtonDisabled,
                                                                 nextMonthButtonDisabled,
                                                             }) => (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}>
                                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                    {"<"}
                                                </button>
                                                <select
                                                    value={getYear(date)}
                                                    onChange={({target: {value}}) => changeYear(value)}
                                                >
                                                    {years.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <select
                                                    value={months[getMonth(date)]}
                                                    onChange={({target: {value}}) =>
                                                        changeMonth(months.indexOf(value))
                                                    }
                                                >
                                                    {months.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>

                                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                    {">"}
                                                </button>
                                            </div>)}
                                        selected={this.state.endDate} onChange={this.setEndDate.bind(this)}/>
                        </div>
                    </div>
                    <div className="d-flex mt-2">
                        <div className="col-md-6">
                            <label htmlFor="passportNumber">Pasport raqami</label>
                            <input id="passportNumber" className="form-control" onChange={this.onChange.bind(this)}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="agreementNumber">Shartnoma raqami</label>
                            <input id="agreementNumber" className="form-control" onChange={this.onChange.bind(this)}/>
                        </div>
                    </div>
                    <div className="form-row mt-3">
                        <div className="col-md-12 text-center">
                        <button className="btn btn-success filter-btn" onClick={this.getData.bind(this)}
                                    autoFocus={true}>
                                <i className="fa fa-filter"/>
                            </button>
                            <button className="ml-1 btn btn-info filter-btn" style={{height: "42px"}}
                                    onClick={this.exportToExcel.bind(this)}><i
                                className="fa fa-file-excel-o"/>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    }
}