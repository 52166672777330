import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import * as _ from './Utils';
import {apiHost, getTopics, isAccountant, isMarketing, logout, pageNotFound, topics} from './Utils';
import './styles/loading.sass';
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Modal} from "react-responsive-modal";
import ReactPaginate from 'react-paginate';
import uz from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import authHeader, {blobAuthHeader} from "./services/auth-header";
import Autocomplete from "react-autocomplete";
import AddAgreementView from "./AddAgreementView";
import ListenerHomeView from "./ListenerHomeView";
import LmsAutocomplete from "./components/LmsAutocomplete";

registerLocale('uz', uz);
setDefaultLocale('uz');

class AgreementList extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
        let height = window.screen.height - 370;
		this.state = {
			openAddModal: false,
			showLoading: true,
			user: user,
			tableRows: [],
			category: '',
			categories: [],
			tendency: '',
			tendencies: [],
			tendencyId: null,
            agreementTypes: [],
			searchKeyword: '',
            startDate: new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0),
			endDate: new Date(),
			regionId: 0,
			statusId: 0,
			categoryId: 0,
			agreementDate: new Date(),

            pageCount: 1,
            perPage: 50,
            data: [],
            offset: 50,
            start: 0,
            maxHeight: height,
			filterModalOpen: false
		};
		this.search = this.search.bind(this);
		this.setValue = this.setValue.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
	}

	setAgreementDate(agreementDate) {
		this.setState({
			agreementDate: agreementDate
		});
	}

	setStartDate(startDate) {
		this.setState({
			startDate: startDate
		});
	}

	setEndDate(endDate) {
		this.setState({
			endDate: endDate
		});
	}

	openPage(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/agreementView', '_blank');
	}

	addListener(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
	}

	downloadAgreement(downloadLink) {
		axios({
			url: apiHost + '/api/agreement/download/' + downloadLink,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					downloadLink + '.pdf'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

	componentDidMount() {
		axios.get(apiHost + '/api/agreement/list?start=' + this.state.start + '&offset=' + this.state.offset, authHeader())
			.then(result => {
				this.populateData(result);
			});
		axios.get(apiHost + '/api/agreement/topicTendencies', authHeader())
			.then(result => {
				if (result.data !== null && result.data.length > 0) {
					this.setState({
						tendencies: result.data
					})
				} else {
					this.setState({
						tendencies: []
					})
				}
			}).catch(error => {
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
	}

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;
		if (name === 'regions') {
			name = 'regionId';
		} else if (name === 'paymentStatus') {
			name = 'statusId';
		} else if (name === 'category') {
			name = 'categoryId';
			this.getTendencies(value);
		}
		this.setState({
			[name]: value
		})
	}

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
            offset: offset,
            start: start
        });
        this.getData(this.state.startDate, this.state.endDate, start, offset);
    };

	onChangeValue(e) {
		this.setState({
			searchKeyword: e.target.value
		})
	}

	editAgreement(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/agreementEdit', '_self');
	}

	removeAgreement(agreement) {
        Swal.fire({
            title: 'Siz haqiqatdan ham ' + agreement.agreementNumber + ' raqamli shartnomani o`chirmoqchimisiz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O`chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.get(apiHost + '/api/agreement/remove?agreementId=' + agreement.id, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O`chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.getData(null, null, this.state.start, this.state.offset);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
					if (error !== undefined && error !== null && error.response.status === 401) {
						logout();
					}
				});
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
	}

	populateData(result) {
        let options = [];
        if (result.status === 200) {
			if (result.data.data !== undefined && result.data.data !== null) {
				options = result.data.data.map(row=> {
					return <tr role="row" key={row.id}>
						<td className="align-middle">{row.id}</td>
						<td className="align-middle">{row.region !== undefined && row.region !== null ? row.region.name : ''}</td>
						<td className="align-middle">
							<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.organizationName}</span>
						</td>
						<td className="align-middle">
							<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.agreementNumber}</span>
						</td>
						<td className="align-middle">{row.agreementDate}</td>
						<td className="align-middle">{row.solution}</td>
						<td className="align-middle">{row.phoneNumber}</td>
						<td className="align-middle">{row.tendency !== undefined && row.tendency !== null ? row.tendency.name : ''}</td>
						{isAccountant() &&
							<>
								<td className="align-middle">{row.allAmount}</td>
								<td className="align-middle">{row.payedAmount}</td>
							</>
						}

						<td className="align-middle">{row.payedAmountPercent + '%'}</td>

						{(isMarketing() || isAccountant()) &&
						<td className="align-middle text-center">
							<span className="c-btn" onClick={this.addListener.bind(this, row.id)} title="Tinglovchi qo`shish">
								<i className="fa fa-plus-square-o fa-fw"/>
							</span>
							<span className="c-btn" onClick={this.downloadAgreement.bind(this, row.downloadLink)} title="Ko`chirib olish">
								<i className="fa fa-download fa-fw"/>
							</span>
							<span className="c-btn" onClick={this.editAgreement.bind(this, row.id)} title="Tahrirlash">
								<i className="fa fa-edit fa-fw"/>
							</span>
							<span className="c-btn" onClick={this.removeAgreement.bind(this, row)} title="O`chirish">
								<i className="fa fa-remove fa-fw"/>
							</span>
						</td>
						}
					</tr>
				});
			}
            this.setState({
                tableRows: options,
                pageCount: result.data.pageCount,
                showLoading: false
            })
		} else {
			this.setState({
                pageCount: 1,
				tableRows: []
			})
		}
	}

	search() {
		if (this.state.searchKeyword !== '') {
			this.setState({
				showLoading: true
			});
			axios.get(apiHost + '/api/agreement/search?keyword=' + this.state.searchKeyword, authHeader())
				.then(result => {
					this.populateData(result);
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		} else {
            this.getData(this.state.startDate, this.state.endDate, 0, 1000000);
		}
	}

	filterData() {
		this.getData(this.state.startDate, this.state.endDate, 0, this.state.offset);
	}

	getData(sDate, eDate, start, offset) {
		this.setState({
			showLoading: true
		});
		let regionId = this.state.regionId;
		let statusId = this.state.statusId;
		let categoryId = this.state.categoryId;
		let tendencyId = this.state.tendency === null || this.state.tendency === '' ? '' : this.state.tendencyId;
		let startDate = sDate !== null && sDate !== '' ? getTime(sDate) : '';
		let endDate = eDate !== null && eDate !== '' ? getTime(eDate) : '';

		this.setState({
			regionId: regionId,
			statusId: statusId,
			categoryId: categoryId,
			tendencyId: tendencyId,
			startDate: startDate,
			endDate: endDate
		})

		axios.get(apiHost + '/api/agreement/list?startDate=' + startDate + '&endDate=' + endDate + '&regionId=' + regionId +
			'&statusId=' + statusId + '&categoryId=' + categoryId + '&tendencyId=' + tendencyId + '&start=' + start + '&offset=' + offset, authHeader())
			.then(result => {
				this.populateData(result);
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	openAddModal(modalName) {
		this.setState({
			[modalName]: true
		});
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		});
	}

	exportToExcel() {
		let regionId = this.state.regionId;
		let statusId = this.state.statusId;
		let categoryId = this.state.categoryId;
		let tendencyId = this.state.tendency === null || this.state.tendency === '' ? '' : this.state.tendencyId;

		let data = {
			"regionId": regionId,
			"statusId": statusId,
			"categoryId": categoryId,
			"tendencyId": tendencyId,
			"startDate": getTime(this.state.startDate),
			"endDate": getTime(this.state.endDate)
		}

		axios.post(apiHost + "/api/reports/agreement/list", data, blobAuthHeader())
			.then(result => {
				if (result.status === 200) {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([result.data]));
					link.setAttribute('download', 'Hisobot.xlsx');

					document.body.appendChild(link);

					link.click();
					link.parentNode.removeChild(link);
				}
			})
	}

	addAgreement() {
		let tendencyType = document.getElementById('tendencyType').value;
        let tendencyId = this.state.tendencyId;
        if (tendencyId === null || Number(tendencyId) === 0) {
            swal('Yo`nalishni tanlang', '', 'error');
            return;
        }
        let regionId = document.getElementById('region').value;
        if (regionId === null || Number(regionId) === 0) {
            swal('Hududni tanlang', '', 'error');
            return;
        }
        let paymentPercent = document.getElementById('paymentPercent').value;

		let agreementDate = this.state.agreementDate;
		if (agreementDate === null || agreementDate === '') {
			swal('Shartnoma vaqtini kiriting', '', 'error');
			return;
		}
		let organizationName = document.getElementById('organizationName').value;
		if (organizationName === null || organizationName === '') {
			swal('Tashkilot nomini kiriting', '', 'error');
			return;
		}
		let pupilCount = document.getElementById('listenerCount').value;
		if (pupilCount === null || pupilCount === '' || Number(pupilCount) <= 0) {
			swal('Tinglovchi soni kiriting', '', 'error');
			return;
		}
		let organizationDirector = document.getElementById('organizationDirector').value;
        if (organizationDirector === null || organizationDirector === '') {
            swal('Tashkilot rahbarini FISHni kiriting', '', 'error');
            return;
        }
		let address = document.getElementById('address').value;
        if (address === null || address === '') {
            swal('Tashkilot manzilini kiriting', '', 'error');
            return;
        }
		let bankName = document.getElementById('bankName').value;
        if (bankName === null || bankName === '') {
            swal('Bank nomini kiriting', '', 'error');
            return;
        }
		let bankAccount = document.getElementById('bankAccount').value;
        if (bankAccount === null || bankAccount === '') {
            swal('Hisob raqamini kiriting', '', 'error');
            return;
        }
		let inn = document.getElementById('inn').value;
		if (inn === null || inn === '') {
			swal('INN raqamini kiriting', '', 'error');
			return;
		}
		let mfo = document.getElementById('mfo').value;
		if (mfo === null || mfo === '') {
			swal('MFO raqamini kiriting', '', 'error');
			return;
		}
		let phoneNumber = document.getElementById('phoneNumber').value;
        if (phoneNumber === null || phoneNumber === '') {
            swal('Telefon raqamini kiriting', '', 'error');
            return;
        }
		let data = new FormData();
		data.append('categoryId', tendencyType);
		data.append('tendencyId', tendencyId);
        data.append('regionId', regionId);
        data.append('paymentPercent', paymentPercent);
        data.append('agreementDate', agreementDate);
        data.append('organizationName', organizationName);
        data.append('organizationDirector', organizationDirector);
        data.append('pupilCount', pupilCount);
        data.append('address', address);
        data.append('bankName', bankName);
        data.append('bankAccount', bankAccount);
        data.append('inn', inn);
        data.append('mfo', mfo);
        data.append('phoneNumber', phoneNumber);

		axios.post(apiHost + '/api/agreement/addCustomAgreement', data, authHeader())
			.then(result => {
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error => {
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    getTendencies(topicId) {
        axios.get(apiHost + '/api/agreement/topicTendencies?id=' + topicId, authHeader())
			.then(result => {
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    this.setState({
                        tendencies: result.data,
                    });
                }
            }
        }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
    }

	onSelectTendency(topicId) {
		localStorage.setItem('topicId', topicId);
		window.open("/addAgreement", "_blank");
	}

	setSelectedItem(idParam, id, nameParam, name) {
		this.setState({
			[idParam]: id,
			[nameParam]: name
		});
	}

	clearSelected(idParam, nameParam) {
		this.setState({
			[idParam]: '',
			[nameParam]: ''
		});
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				{this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
				}
				<div className="card card-body shadow-sm">
					<div className="form-row">
						<div className="col-md-1 p-0 d-flex">
							<button className="btn btn-primary"
									onClick={this.openAddModal.bind(this, 'filterModalOpen')}>
								<i className="fa fa-filter"/></button>
							{isMarketing() &&
								<button className="mx-1 btn btn-info"
										onClick={this.openAddModal.bind(this, 'openAddModal')}
										title="Shartnoma qo`shish"><i
									className="fa fa-plus-square-o"/></button>
							}
						</div>
						<div className="col-md-5 text-right">
							<h3>Shartnomalar ro`yxati</h3>
						</div>
						<div className="col-md-6">
							<form className="form-inline float-right">
								<div className="input-group">
									<input className="form-control" id="searchBox" placeholder="Qidiruv so`zi"
									       aria-label="Qidirish" aria-describedby="basic-addon2" type="text"
									       onChange={this.onChangeValue}/>
									<div className="input-group-append">
										<button className="btn btn-outline-info" type="button"
										        onClick={this.search}><i className="fa fa-search"/></button>
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="form-row mt-1">
						<div className="col-md-12 order-md-1 pt-2">
							<table className="table table-striped table-bordered shadow" style={{tableLayout:"fixed"}}>
								<thead>
								<tr>
									<th scope="col" className="align-middle" style={{width: "5%"}}>№</th>
									<th scope="col" className="align-middle">Hudud nomi</th>
									<th scope="col" className="align-middle">Tashkilot nomi</th>
									<th scope="col" className="align-middle">Shartnoma raqami</th>
									<th scope="col" className="align-middle">Shartnoma vaqti</th>
									<th scope="col" className="align-middle" style={{width: "9%"}}>INN</th>
									<th scope="col" className="align-middle">Telefon raqami</th>
									<th scope="col" className="align-middle">Yo`nalishi</th>
									{isAccountant() &&
										<>
											<th scope="col" className="align-middle">Jami summa</th>
											<th scope="col" className="align-middle">To‘langan summa</th>
										</>
									}
									<th scope="col" className="align-middle" style={{width: "9%"}}>To‘langan summa, %</th>
									{(isAccountant() || isMarketing()) &&
									<th scope="col" className="align-middle" style={{width: "9%"}}>Amal</th>
									}
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 order-md-1 text-center">
							<ReactPaginate
								previousLabel={'««'}
								nextLabel={'»»'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={1}
								pageRangeDisplayed={5}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination'}
								subContainerClassName={'pagination'}
								activeClassName={'listing-page-item'}
							/>
						</div>
					</div>
				</div>
				<Modal open={this.state.openAddModal} onClose={this.onCloseModal.bind(this, 'openAddModal')} center>
					<div className="mt-4">
						<div className="form-row mb-3">
							<div className="col-md-12 text-center">
								<h4 className="font-weight-bolder">Shartnoma qo`shish</h4>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 1)}>
								<div className="topic-name boxName" style={{width: "350px"}}>
									<span>{topics[0]}</span>
								</div>
							</div>

							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 4)}>
								<div className="topic-name boxName" style={{width: "350px"}}>
									<span>{topics[3]}</span>
								</div>
							</div>
						</div>

						<div className="form-row">
							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 3)}>
								<div className="topic-name boxName" style={{width: "350px"}}>
									<span>{topics[2]}</span>
								</div>
							</div>

							<div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 2)}>
								<div className="topic-name boxName" style={{width: "350px"}}>
									<span>{topics[1]}</span>
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<Modal open={this.state.filterModalOpen} onClose={this.onCloseModal.bind(this, 'filterModalOpen')}
					   center>
					<div className="container-md pt-4">
						<div className="form-row">
							<div className="col-md-12">
								<label htmlFor="category">Kategoriya</label>
								<select id="category" className="form-control" value={this.state.categoryId} onChange={this.setValue}>
									{getTopics()}
								</select>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12">
								<label>Yo`nalishlar</label>
								<LmsAutocomplete items = {this.state.tendencies} selectedValue = {this.state.tendency}
												 idParam="tendencyId" nameParam="tendency" clearSelected={this.clearSelected.bind(this)}
												 selectedId = {this.state.tendencyId} selectItem={this.setSelectedItem.bind(this)}/>
							</div>
						</div>
						<div className="form-row">
							<div className="col-md-6">
								<label className="col-form-label" htmlFor="startDate">Boshlanish sanasi</label>
								<br/>
								<DatePicker className="form-control" id="startDate" locale="uz"
											dateFormat="dd.MM.yyyy" selected={this.state.startDate}
											showMonthDropdown={true} showYearDropdown={true}
											onChange={this.setStartDate.bind(this)}/>
							</div>
							<div className="col-md-6">
								<label className="col-form-label" htmlFor="endDate">Tugash sanasi</label>
								<br/>
								<DatePicker className="form-control" id="endDate" locale="uz"
											dateFormat="dd.MM.yyyy" selected={this.state.endDate}
											showMonthDropdown={true} showYearDropdown={true}
											onChange={this.setEndDate.bind(this)}/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-6">
								<label htmlFor="regions">Hudud</label>
								<select id="regions" className="form-control" value={this.state.regionId}
										onChange={this.setValue}>
									{_.getRegions()}
								</select>
							</div>
							<div className="col-md-6">
								<label htmlFor="paymentStatus">To`lov foizi</label>
								<select id="paymentStatus" className="form-control" value={this.state.statusId}
										onChange={this.setValue}>
									<option value="0">Hammasi</option>
									<option value="1">To`lagan</option>
									<option value="2">Qisman to`lagan</option>
									<option value="3">To`lamagan</option>
								</select>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-12 text-center">
								<button className="btn btn-success" onClick={this.filterData.bind(this)}>
									<i className="fa fa-filter"/>
								</button>
								<button className="ml-1 btn btn-info" style={{height: "42px"}}
										onClick={this.exportToExcel.bind(this)}><i
									className="fa fa-file-excel-o"/>
								</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
	)
	}
	}

	export default AgreementList;