import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import * as _ from './Utils';
import {Modal} from "react-responsive-modal";
import authHeader from "./services/auth-header";
import {logout, pageNotFound} from "./Utils";

class UserList extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
			user: user,
			editedUser: null,
			userId: null,
			tableRows: [],
			searchKeyword: '',
			openEditModal: false
		};
	}

	componentDidMount() {
		axios.get(_.apiHost + '/api/user/list', authHeader())
			.then(result => {
				console.log(result);
				let options = [];
				if (result.status === 200) {
					if (result.data !== undefined && result.data !== null) {
						options = result.data.map(row=> {
							return <tr key={row.id}>
								<td className="align-middle">{row.id}</td>
								<td className="align-middle">
									<span className="linkStyle" onClick={this.openModal.bind(this, row)}>{row.lastName + ' ' + row.firstName}</span>
								</td>
								<td className="align-middle">{row.roles.map(r=><span style={{margin: "5px"}}>{r.name}</span>)}</td>
								<td className="align-middle">{row.userName}</td>
							</tr>;
						});
					}
				}
				this.setState({
					tableRows: options
				})
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	openModal(user) {
		this.setState({
			editedUser: user,
			openEditModal: true
		})
	}

	onCloseModal() {
		this.setState({
			openEditModal: false
		})
	}

	editUser() {
		let lastName = document.getElementById('lastName').value;
		let firstName = document.getElementById('firstName').value;
		let userName = document.getElementById('userName').value;

		let data = new FormData();
		data.append('userId', this.state.editedUser.id);
		data.append('lastName', lastName);
		data.append('firstName', firstName);
		data.append('userName', userName);

		axios.post(_.apiHost + '/api/user/edit', data, authHeader())
			.then(result=>{
				if (result.status === 200) {
					swal(result.data.message, '', 'success');
				} else {
					swal(result.data.message, '', 'error');
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				<div className="container card card-body mt-1 shadow-sm">
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-12 text-center">
									<h3>Foydalanuvchilar ro`yxati</h3>
								</div>
							</div>
						</div>
						<div className="col-md-12 order-md-1 pt-2">
							<table className="table table-striped table-bordered shadow">
								<thead>
								<tr>
									<th scope="col" className="align-middle">№</th>
									<th scope="col" className="align-middle">Foydalanuvchi</th>
									<th scope="col" className="align-middle">Rol</th>
									<th scope="col" className="align-middle">Login</th>
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
				</div>
				{this.state.editedUser !== null &&
				<Modal open={this.state.openEditModal} onClose={this.onCloseModal.bind(this)} center>
					<div>
						<div className="form-row mt-2">
							<div className="col-md-6">
								<label htmlFor="lastName">Familiyasi</label>
								<input id="lastName" className="form-control"
								       defaultValue={this.state.editedUser.lastName}/>
							</div>
							<div className="col-md-6">
								<label htmlFor="firstName">Ismi</label>
								<input id="firstName" className="form-control"
								       defaultValue={this.state.editedUser.firstName}/>
							</div>
						</div>
						<div className="form-row mt-2">
							<div className="col-md-6">
								<label htmlFor="userName">Login</label>
								<input id="userName" className="form-control"
								       defaultValue={this.state.editedUser.userName}/>
							</div>
						</div>
						<div className="form-row mt-2 text-center">
							<div className="col-md-12">
								<button className="btn btn-success" onClick={this.editUser.bind(this)}>Saqlash</button>
							</div>
						</div>
					</div>
				</Modal>
				}
			</div>
		)
	}
}

export default UserList;