import React, {Component} from 'react';
import axios from 'axios';
import * as _ from './Utils';
import {apiHost, isController, isMainController, isMarketing, logout, pageNotFound} from './Utils';
import Swal from 'sweetalert2';
import Moment from "react-moment";
import {Link} from "react-router-dom";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {uz} from 'date-fns/locale/uz';
import getTime from 'date-fns/getTime';
import authHeader, {blobAuthHeader} from "./services/auth-header";
import {getMonth, getYear} from "date-fns";
import {Modal} from "react-responsive-modal";
const months = [
	"Yanvar",
	"Fevral",
	"Mart",
	"Aprel",
	"May",
	"Iyun",
	"Iyul",
	"Avgust",
	"Sentyar",
	"Oktyar",
	"Noyabr",
	"Dekabr",
];

registerLocale('uz', uz);
setDefaultLocale('uz');
const years = [2021,2022,2023,2024,2025,2026,2027,2028,2029,2030];


export default class GroupListView extends Component {

	constructor() {
		super();
        let urlParams = new URLSearchParams(window.location.search);
        let groupType = urlParams.get('type');
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
            groupType: groupType !== null ? groupType : 'all',
			user: user,
            status: '',
            category: '',
			tableRows: [],
            searchKeyword: '',
            startDate: new Date(2024, 0, 1, 0, 0, 0, 0),
            endDate: new Date(),
            examinationDate: new Date(),
			showLoading: true,
			filterModalOpen: false,
		};
		this.search = this.search.bind(this);
		this.onChangeValue = this.onChangeValue.bind(this);
	}

    setStartDate(startDate) {
        this.setState({
            startDate: startDate
        });
        // this.getData(startDate, this.state.endDate, this.state.status, this.state.category);
    }

    setEndDate(endDate) {
        this.setState({
            endDate: endDate
        });
        // this.getData(this.state.startDate, endDate, this.state.status, this.state.category);
    }

    onChange(e) {
		let name = e.target.id;
		let value = e.target.value;
        this.setState({
            [name]: value
        });
        // this.getData(this.state.startDate, this.state.endDate, name==='status' ? value : this.state.status, name==='category' ? value : this.state.category);
    }

	openPage(courseId) {
		localStorage.setItem('courseId', courseId);
		window.open(window.location.protocol + '//' + window.location.host + '/groupView', '_self');
	}

	addListener(agreementId) {
		localStorage.setItem('agreementId', agreementId);
		window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
	}

	createGroupOrder(groupId) {
		axios({
			url: apiHost + '/api/course/downloadDoc?groupId=' + groupId,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					'Guruh_' + groupId + '.docx'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

    downloadCertificatesArchive(groupId) {
		axios({
			url: apiHost + '/api/course/downloadCertificatesArchive?groupId=' + groupId,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					'Guruh_' + groupId + '.zip'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

    removeGroup(group) {
        Swal.fire({
            title: 'Siz haqiqatdan ham "' + group.name + '" nomli guruhni o`chirmoqchimisiz?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O`chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.get(_.apiHost + '/api/course/remove?groupId=' + group.id, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O`chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.getData(this.state.startDate, this.state.endDate, this.state.status, this.state.category);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
					if (error !== undefined && error !== null && error.response.status === 401) {
						logout();
					}
				});
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
    }

	filterData() {
		this.getData(this.state.startDate, this.state.endDate, this.state.status, this.state.category);
	}

    getData(startDate, endDate, statusId, categoryId) {
		this.setState({
			showLoading: true
		});
        let tendencyId = localStorage.getItem('tendencyId');
		if (tendencyId === undefined || tendencyId === null) {
			tendencyId = '';
		}
        axios.get(_.apiHost + '/api/course/list?type=' + this.state.groupType + '&tendencyId=' + tendencyId +
			'&startDate=' + getTime(startDate) + '&endDate=' + getTime(endDate) + '&statusId=' + statusId + '&categoryId=' + categoryId,
			authHeader())
            .then(result => {
                if (result.status === 200) {
                    let options = [];
                    if (result.data !== undefined && result.data !== null) {
                        for (let i = 0; i < result.data.length; i++) {
                            let row = result.data[i];
                            options.push(<tr key={row.id}>
								<td className="align-middle">{row.id}</td>
								<td className="align-middle">
									<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.name}</span>
								</td>
								<td className="align-middle">{row.status !== undefined && row.status !== null ? row.status.name : ''}</td>
								<td className="align-middle text-center">{row.startDate !== null ? <Moment format="DD.MM.YYYY">{row.startDate}</Moment> : ''}</td>
								<td className="align-middle text-center">{row.endDate !== null ? <Moment format="DD.MM.YYYY">{row.endDate}</Moment> : ''}</td>
								<td className="align-middle text-center">{row.examDate !== null ? <Moment format="DD.MM.YYYY">{row.examDate}</Moment> : ''}</td>
								<td className="align-middle text-center">{row.listenerCount}</td>
								<td className="align-middle text-center">
									{(isMarketing() || isController() || isMainController()) &&
										<>
											<button className="btn btn-info mr-1" title="Buyruq chiqarish" onClick={this.createGroupOrder.bind(this, row.id)}><i className="fa fa-file-word-o"/></button>
											<button className="btn btn-info mr-1" title="Sertifikatlarni arxivlab ko`chirib olish" onClick={this.downloadCertificatesArchive.bind(this, row.id)}><i className="fa fa-file-archive-o"/></button>
											<button className="btn btn-danger" title="O`chirish" onClick={this.removeGroup.bind(this, row)}><i className="fa fa-remove"/></button>
										</>
									}
									</td>
							</tr>);
                        }
                    }
                    this.setState({
                        tableRows: options,
						showLoading: false
                    })
                } else {
                    this.setState({
                        tableRows: [],
						showLoading: false
                    })
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	componentDidMount() {
		this.getData(this.state.startDate, this.state.endDate, this.state.status, this.state.category);
        document.getElementById('searchBox').addEventListener("keyUp", function(event) {
            if (event.key === 'Enter' ||event.keyCode === 13) {
                event.preventDefault();
                document.getElementById("searchButton").click();
            }
        });
	}

    _handleKeyDown(e) {
        if (e.key === 'Enter'  || e.keyCode === 13) {
            e.preventDefault();
            document.getElementById("searchButton").click();
        }
    }

	onChangeValue(e) {
		this.setState({
			searchKeyword: e.target.value
		})
	}

	search() {
		if (this.state.searchKeyword !== '') {
			axios.get(_.apiHost + '/api/course/search?keyword=' + this.state.searchKeyword, authHeader())
				.then(result => {
					if (result.status === 200) {
						let options = [];
						if (result.data !== null) {
							for (let i = 0; i < result.data.length; i++) {
								let row = result.data[i];
								options.push(<tr key={row.id}>
									<td className="align-middle">{row.id}</td>
									<td className="align-middle">
										<span className="linkStyle" onClick={this.openPage.bind(this, row.id)}>{row.name}</span>
									</td>
									<td className="align-middle">{row.status !== undefined && row.status !== null ? row.status.name : ''}</td>
									<td className="align-middle">{row.startDate !== null ? <Moment format="DD.MM.YYYY">{row.startDate}</Moment> : ''}</td>
									<td className="align-middle">{row.endDate !== null ? <Moment format="DD.MM.YYYY">{row.endDate}</Moment> : ''}</td>
									<td className="align-middle">{row.examDate !== null ? <Moment format="DD.MM.YYYY">{row.examDate}</Moment> : ''}</td>
									<td className="align-middle">{row.listenerCount}</td>
									<td className="align-middle">
										{(isMarketing() || isController() || isMainController()) &&
											<>
												<button className="btn btn-info mr-1" title="Buyruq chiqarish"
														onClick={this.createGroupOrder.bind(this, row.id)}><i
													className="fa fa-file-word-o"/></button>
												<button className="btn btn-info mr-1"
														title="Sertifikatlarni arxivlab ko`chirib olish"
														onClick={this.downloadCertificatesArchive.bind(this, row.id)}><i
													className="fa fa-file-archive-o"/></button>
												<button className="btn btn-danger" title="O`chirish"
														onClick={this.removeGroup.bind(this, row)}><i className="fa fa-remove"/>
												</button>
											</>
									}
									</td>
								</tr>);
							}
						}
						this.setState({
							tableRows: options
						})
					} else {
						this.setState({
							tableRows: []
						})
					}
				}).catch(error => {
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	exportToExcel() {
		let statusId = document.getElementById('status').value;
		let categoryId = document.getElementById('category').value;

		let data = {
			"statusId": statusId,
			"categoryId": categoryId,
			"startDate": getTime(this.state.startDate),
			"endDate": getTime(this.state.endDate),
			"groupType": this.state.groupType
		}

		axios.post(apiHost + "/api/reports/group/list", data, blobAuthHeader())
		.then(result => {
			if (result.status === 200) {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute('download', 'Hisobot.xlsx');

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			}
		})
    }

	openModal(modalName) {
		this.setState({
			[modalName]: true
		});
	}

	closeModal(modalName) {
		this.setState({
			[modalName]: false
		});
	}

	render() {
		pageNotFound();
		return (
			<div className="content">
				{this.state.showLoading &&
					<div className="container">
						<div className="loader">
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--dot"/>
							<div className="loader--text"/>
						</div>
					</div>
				}
				<div className="card card-body shadow-sm">
					<div className="form-row">
						<div className="col-md-2 p-0">
							<button className="btn btn-primary"
									onClick={this.openModal.bind(this, 'filterModalOpen')}>
								<i className="fa fa-filter"/></button>
							<Link to={"/addGroup?type=" + this.state.groupType} className="btn btn-info ml-1"><i
								className="fa fa-plus"/></Link>
						</div>
						<div className="col-md-7 text-center">
							<h3>Guruhlar ro`yxati</h3>
						</div>
						<div className="col-md-3">
							<div className="input-group">
								<input className="form-control" id="searchBox" placeholder="Qidiruv so`zi"
									   aria-label="Qidirish" aria-describedby="basic-addon2" onChange={this.onChangeValue} onKeyDown={this._handleKeyDown.bind(this)}/>
								<div className="input-group-append">
									<button className="btn btn-outline-info" type="button"
											id="searchButton" onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
								</div>
							</div>
						</div>
					</div>
					<div className="form-row">
						<div className="col-md-12 order-md-1 pt-2">
							<table className="table table-striped table-bordered">
								<thead>
								<tr>
									<th scope="col" className="align-middle">№</th>
									<th scope="col" className="align-middle">Guruh nomi</th>
									<th scope="col" className="align-middle">Statusi</th>
									<th scope="col" className="align-middle">Boshlangan vaqti</th>
									<th scope="col" className="align-middle">Tugagan vaqti</th>
									<th scope="col" className="align-middle">Imtihon vaqti</th>
									<th scope="col" className="align-middle">Tinglovchi soni</th>
									<th scope="col" className="align-middle" width="12%">Amal</th>
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<Modal open={this.state.filterModalOpen} onClose={this.closeModal.bind(this, 'filterModalOpen')} center>
					<div className="container-md" style={{minHeight: "450px"}}>
						<div className="d-flex">
							<div className="col-md-6 mr-1">
								<label htmlFor="category">Yo`nalishlar</label>
								<select id="category" className="form-control" onChange={this.onChange.bind(this)}>
									<option value="0">Hammasi</option>
									<option value="2">Mediatorlik kurslari</option>
									<option value="2">Qayta tayyorlash kurslari</option>
									<option value="3">Malaka oshirish kurslari</option>
									<option value="4">Qisqa muddatli kurslar</option>
								</select>
							</div>
							<div className="col-md-6">
								<label htmlFor="status">Holati</label>
								<select id="status" className="form-control" value={this.state.statusId}
										onChange={this.onChange.bind(this)}>
									<option value="0">Hammasi</option>
									<option value="1">Boshlanmagan</option>
									<option value="2">O`quv jarayonida</option>
									<option value="5">Yakunlangan</option>
								</select>
							</div>
						</div>
						<div className="d-flex">
							<div className="col-md-6 mr-1">
								<label className="col-form-label" htmlFor="startDate">Boshlanish sanasi</label>
								<br/>
								<DatePicker className="form-control" id="startDate" showIcon icon="fa fa-calendar" locale="uz" dateFormat="dd.MM.yyyy"
											renderCustomHeader={({
																	 date,
																	 changeYear,
																	 changeMonth,
																	 decreaseMonth,
																	 increaseMonth,
																	 prevMonthButtonDisabled,
																	 nextMonthButtonDisabled,
																 }) => (
												<div
													style={{
														margin: 10,
														display: "flex",
														justifyContent: "center",
													}}>
													<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
														{"<"}
													</button>
													<select
														value={getYear(date)}
														onChange={({ target: { value } }) => changeYear(value)}
													>
														{years.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<select
														value={months[getMonth(date)]}
														onChange={({ target: { value } }) =>
															changeMonth(months.indexOf(value))
														}
													>
														{months.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
														{">"}
													</button>
												</div>
											)}
											selected={this.state.startDate} onChange={this.setStartDate.bind(this)} />
							</div>
							<div className="col-md-6">
								<label className="col-form-label" htmlFor="endDate">Tugash sanasi</label>
								<br/>
								<DatePicker className="form-control" id="endDate" showIcon icon="fa fa-calendar" locale="uz" dateFormat="dd.MM.yyyy"
											renderCustomHeader={({
																	 date,
																	 changeYear,
																	 changeMonth,
																	 decreaseMonth,
																	 increaseMonth,
																	 prevMonthButtonDisabled,
																	 nextMonthButtonDisabled,
																 }) => (
												<div
													style={{
														margin: 10,
														display: "flex",
														justifyContent: "center",
													}}>
													<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
														{"<"}
													</button>
													<select
														value={getYear(date)}
														onChange={({ target: { value } }) => changeYear(value)}
													>
														{years.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<select
														value={months[getMonth(date)]}
														onChange={({ target: { value } }) =>
															changeMonth(months.indexOf(value))
														}
													>
														{months.map((option) => (
															<option key={option} value={option}>
																{option}
															</option>
														))}
													</select>

													<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
														{">"}
													</button>
												</div>)}
											selected={this.state.endDate} onChange={this.setEndDate.bind(this)} />
							</div>
						</div>
						<div className="form-row mt-3">
							<div className="col-md-12 text-center">
								<button className="btn btn-success filter-btn" onClick={this.filterData.bind(this)}
										autoFocus={true}>
									<i className="fa fa-filter"/>
								</button>
								<button className="ml-1 btn btn-info filter-btn" style={{height: "42px"}}
										onClick={this.exportToExcel.bind(this)}><i
									className="fa fa-file-excel-o"/>
								</button>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}