import React, {Component} from 'react';
import {logout, openPage, pageNotFound} from "./Utils";

class MarketingHomeView extends Component {

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		if (!user.roles.includes('MARKETING')) {
			logout();
		}
		return (
			<div className="container-sm">
				<div style={{margin: "auto", width: "740px", minWidth: "740px"}}>
					<div className="form-row">
						<div className="col-md-6 text-center p-1" onClick={() => openPage('/agreements')}>
							<div className="topic-name boxName">
								<img src="/images/agreements.jpg" alt='Shartnomalar ro`yxati'/>
								<span>Shartnomalar</span>
							</div>
						</div>
						<div className="col-md-6 text-center p-1"
							 onClick={() => openPage('/tendencies?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/tendencies.jpg" alt='Yo`nalishlar ro`yxati'/>
								<span>Yo`nalishlar</span>
							</div>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-6 text-center p-1"
							 onClick={() => openPage('/listeners?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/listeners.jpg" alt='Tinglovchilar ro`yxati'/>
								<span>Tinglovchilar</span>
							</div>
						</div>
						<div className="col-md-6 text-center p-1"
							 onClick={() => openPage('/groups?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/groups.jpg" alt='Guruhlar ro`yxati'/>
								<span>Guruhlar</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default MarketingHomeView;