import React from "react";
import Autocomplete from "react-autocomplete";

export default class LmsAutocomplete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedValue: props.selectedValue,
            selectedId: props.selectedId,
            items: props.items
        }
    }

    clearSelected() {
        this.setState({
            selectedValue: '',
            selectedId: null
        });
        this.props.clearSelected(this.props.idParam, this.props.nameParam);
    }

    render() {
        return (
            <div className="d-flex">
                <Autocomplete
                    wrapperStyle={{width: "100%"}}
                    inputProps={{
                        className: 'form-control'
                    }}
                    value={this.state.selectedValue}
                    items={this.state.items}
                    getItemValue={item => item.name}
                    shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                    renderItem={(item, highlighted) =>
                        <div key={item.id}
                             style={{backgroundColor: highlighted ? '#eee' : 'transparent'}}>
                            {item.name}
                        </div>
                    }
                    renderMenu={item => (
                        <div className="dropdown">
                            {item}
                        </div>
                    )}

                    onChange={(event, val) => {
                        this.setState({
                            selectedValue: val
                        })
                    }}
                    onSelect={(event, val) => {
                        this.props.selectItem(this.props.idParam, val.id, this.props.nameParam, val.name);
                        this.setState({
                            selectedValue: val.name,
                            selectedId: val.id
                        })
                    }}
                />
                <span className="d-flex m-auto ct-pointer" onClick={this.clearSelected.bind(this)}>
                    <i className="fa fa-remove ml-5px"/>
                </span>
            </div>
        )
    }
}