import React, {Component} from "react";
import axios from "axios";
import * as _ from "./Utils";
import {
	apiHost,
	getTopics,
	isAccountant,
	isController,
	isMainController,
	isMarketing,
	logout,
	pageNotFound
} from "./Utils";
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import './styles/loading.sass';
import ReactPaginate from 'react-paginate';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import DatePicker from "react-datepicker";
import getTime from 'date-fns/getTime';
import authHeader, {blobAuthHeader} from "./services/auth-header";
import LmsAutocomplete from "./components/LmsAutocomplete";

export default class ListenersListView extends Component {

	constructor(props) {
		super(props);
        let urlParams = new URLSearchParams(window.location.search);
        let listenerType = urlParams.get('type');
        let height = window.screen.height - 360;
		this.state = {
            listenerType: listenerType,
			showLoading: true,
			tableRows: [],
			modalOpen: false,
			changeGroupModalOpen: false,
			filterModalOpen: false,
			tendency: '',
			tendencyId: '',
			tendencies: [],
			infoDocModal: false,
			generateCertModal: false,
			listener: null,
			searchKey: '',
			category: 0,
			status: 0,
            group: '',
            groupId: 0,
			groups: [],
			groupList: [],
			regionId: 0,
			paymentStatus: 0,
			categories: [],
            payedAmount: 0,
            pageCount: 1,
            perPage: 50,
            data: [],
            offset: 50,
            start: 0,
            maxHeight: height,
			selectedListener: {},
			docDate: new Date(),
			orderDate: new Date()
		};
		this.setValue = this.setValue.bind(this);
	}

	componentDidMount() {
		this.getTendencies('');
		axios.get(apiHost + '/api/course/list?type=' + this.state.listenerType, authHeader())
			.then(result => {
				let items = [];
				if (result.data !== null && result.data.length>0) {
					items = result.data.map(item=>{
						return <option key={'t_' + item.id} value={item.id}>{item.name}</option>
					});
					items.unshift(<option key={'t_' + 0} value="0">Hammasi</option>);
				}
				this.setState({
					groups: items,
					groupList: result.data.map(item=>{
						return {'id': item.id, 'name': item.name}
					})
				})
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
		this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
	}

	getTendencies(topicId) {
		axios.get(apiHost + '/api/agreement/topicTendencies?id=' + topicId, authHeader())
			.then(result => {
				this.setState({
					tendencies: result.data
				})
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		});
	}

	filterData() {
		this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
	}

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil((selected + 1) * this.state.perPage);
        let start = selected === 0 ? 0 : selected * this.state.perPage + 1;

        this.setState({
			offset: offset,
			start: start
		});
        this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, start, offset);
    };

	setValue(e) {
		let name = e.target.id;
		let value = e.target.value;

		if (name === 'regions') {
			name = 'regionId';
		} else if (name === 'group') {
			name = 'groupId';
		}
        this.setState({
			[name]: value,
		});
	}

	setSelectedItem(idParam, id, nameParam, name) {
		this.setState({
			[idParam]: id,
			[nameParam]: name
		});
	}

	clearSelected(idParam, nameParam) {
		this.setState({
			[idParam]: '',
			[nameParam]: ''
		});
	}

	fetchData(status, paymentStatus, categoryId, groupId, regionId, start, offset) {
		this.setState({
			showLoading: true
		});
		axios.get(apiHost + '/api/listener/list?type=' + this.state.listenerType + '&status=' + status + '&paymentStatus=' + paymentStatus +
			'&categoryId=' + categoryId + '&groupId=' + groupId + '&regionId=' + regionId + '&start=' + start + '&offset=' + offset, authHeader())
			.then(result => {
				this.populateData(result);
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	addListener() {
		localStorage.removeItem('listenerId');
		window.open(window.location.protocol + '//' + window.location.host + '/addListener?type=' + this.state.listenerType, '_self');
	}

	edit(listenerId) {
		localStorage.setItem('listenerId', listenerId);
		window.open(window.location.protocol + '//' + window.location.host + '/addListener', '_self');
	}

	checkCertificate(row) {
		axios({
			url: apiHost + '/api/listener/downloadCertificate/' + row.certificateLink,
			method: "GET",
			headers: authHeader().headers,
			responseType: "blob"
		})
			.then(result => {
				const link = document.createElement("a");
				link.href = window.URL.createObjectURL(new Blob([result.data]));
				link.setAttribute(
					'download',
					row.certificateLink + '.pdf'
				);

				document.body.appendChild(link);

				link.click();
				link.parentNode.removeChild(link);
			})
	}

    enterPayedAmount() {
        axios.post(apiHost + '/api/listener/enterPayedAmount?listenerId=' + this.state.listener.id + '&amount=' +
			this.state.payedAmount, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
                    swal(result.data.message, "", "success");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    changeGroup() {
        axios.post(apiHost + '/api/listener/changeGroup?listenerId=' + this.state.listener.id + '&groupId=' +
			this.state.groupId, null, authHeader())
            .then(result => {
                if (result.status === 200) {
                    this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
                    swal(result.data.message, "", "success");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    openGroupPage(courseId) {
        localStorage.setItem('courseId', courseId);
        window.open(window.location.protocol + '//' + window.location.host + '/groupView', '_blank');
    }

    openAgreementPage(agreementId) {
        localStorage.setItem('agreementId', agreementId);
        window.open(window.location.protocol + '//' + window.location.host + '/agreementView', '_blank');
    }

	showInfoDocModal(listener) {
		this.setState({
			infoDocModal: true,
			selectedListener: listener
		})
	}
	hideInfoDocModal() {
		this.setState({
			infoDocModal: false,
			selectedListener: {}
		})
	}

    getInfoDoc() {
		let docDate = getTime(this.state.docDate);
		let registerNumber = document.getElementById('registerNumber').value;
		let orderDate = getTime(this.state.orderDate);
		let orderNumber = document.getElementById('orderNumber').value;
        axios.get(apiHost + '/api/listener/infoDoc?listenerId=' + this.state.selectedListener.id + '&docDate=' + docDate + '&registerNumber=' +
			registerNumber + '&orderDate=' + orderDate + '&orderNumber=' + orderNumber, authHeader())
            .then(result => {
                if (result.status === 200) {
                    window.open(apiHost + result.data, "_blank");
                } else if (result.status === 500) {
                    swal(result.data.message, "", "error");
                }
            }).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	populateData(result) {
		const query = new URLSearchParams(window.location.search);
		const userType = query.get('type');
		let listeners = [];
		if (result.status === 200) {
			listeners = result.data.data.map((row, index)=> {
				return <tr key={'llv_' + index} style={{backgroundColor: row.status.id === 1 ? '#d6d6d6' :
					row.status.id === 3 ? '#00ffff' : row.status.id === 4 ? '#FFFC20' : (row.status.id === 5 || row.status.id === 6) ? '#40FF6B' : '#FFFFFF'}}>
					<td>{row.id}</td>
					<td>{row.lastName + ' ' + row.firstName + ' ' + row.middleName}</td>
					<td>{row.passportNumber}</td>
					<td>{row.phoneNumber}</td>
					<td>{row.tendencyName}</td>
					<td><span className="linkStyle" title={row.group.name} onClick={this.openGroupPage.bind(this, row.group.id)}>{row.group.name}</span></td>
					<td>{row.registerNumber}</td>
					<td>{row.certificateNumber}</td>
                    {this.state.listenerType === 'paid' ? <td><span className="linkStyle" title={row.agreement.name} onClick={this.openAgreementPage.bind(this, row.agreement.id)}>{row.agreement.name}</span></td> : ''}
					<td>{row.status.name}</td>
                    {this.state.listenerType === 'paid' ? <td style={{backgroundColor: row.paymentStatus === 2 ? "#ffee22" : "transparent"}}>{row.paymentStatus === 1 ? "To‘lanmagan" : row.paymentStatus === 2 ? "Qisman to‘langan" : row.paymentStatus === 3 ? "To‘langan" : ""}</td> : ''}
					<td>
                        {!isAccountant() &&
						<div className="d-flex">
                            {((row.tendencyName !== 'Mediator' && this.state.listenerType === 'paid' && row.status.id === 5
							&& isMainController() && (row.certificateNumber === null || row.certificateNumber === '')
									&& (row.registerNumber !== undefined && row.registerNumber !== null && row.registerNumber !== '')
							&& Number(row.paymentStatus) === 3) || (userType === 'free' && row.status.id === 5 && (row.certificateNumber === null || row.certificateNumber === ''))) &&
							<span className="c-btn" title="Sertifikat berish" onClick={this.generateCertificate.bind(this, row.id)}>
								<i className="fa fa-address-card-o mr-1"/>
							</span>
                            }
                            {(row.tendencyName === 'Mediator' && row.status.id === 5 && isMainController()
									&& (row.registerNumber !== undefined && row.registerNumber !== null && row.registerNumber !== '')
									&& (row.certificateNumber === null || row.certificateNumber === '')) &&
							<span className="c-btn" title="Sertifikat berish" onClick={this.openGenerateCertModal.bind(this, row)}>
								<i className="fa fa-address-card-o mr-1"/>
							</span>
                            }
                            {((row.status.id === 6 && (isController() || isMainController()) &&
								(row.certificateNumber !== null && row.certificateNumber.length > 0)) ||
								(userType === 'free' && row.status.id === 5 && (row.certificateNumber !== null && row.certificateNumber !== ''))) &&
								<span className="c-btn" title="Sertifikatni tekshirish"
									  onClick={this.checkCertificate.bind(this, row)}>
									<i className="fa fa-download mr-1"/>
								</span>
                            }
                            {(isMarketing() || isController() || isMainController()) &&
								<span className="c-btn" title="Tahrirlash"
									  onClick={this.edit.bind(this, row.id)}>
									<i className="fa fa-edit mr-1"/>
								</span>
                            }
                            {(isController() || isMainController()) &&
								<span className="c-btn" title="O‘qishdan chetlatish"
									  onClick={this.changeStatus.bind(this, row)}>
									<i className="fa fa-exclamation-triangle mr-1" />
								</span>
                            }
                            {(isMainController() && row.certificateNumber !== null && row.certificateNumber !== '') &&
								<span className="c-btn" title="Sertifikatni bekor qilish"
									  onClick={this.revertCertificate.bind(this, row)}>
									<i className="fa fa-recycle mr-1"/>
								</span>
                            }
                            {(isMarketing() || isController() || isMainController()) &&
								<span className="c-btn" title="O‘chirish"
									  onClick={this.removeListener.bind(this, row)}>
									<i className="fa fa-remove mr-1" />
								</span>
                            }
                            {(isMarketing() || isController() || isMainController()) &&
							<>
								<span className="c-btn" title="Guruhni o‘zgartirish"
									  onClick={this.openChangeGroupModal.bind(this, row)}>
									<i className="fa fa-refresh mr-1" />
								</span>
								<span className="c-btn" title="Ma'lumotnoma"
									  onClick={this.showInfoDocModal.bind(this, row)}>
									<i className="fa fa-info mr-1" />
								</span>
							</>
                            }
						</div>
                        }
                        {isAccountant() &&
							<span className="c-btn" title="Summani kiritish"
								  onClick={this.inputAmount.bind(this, row)}>
								<i className="fa fa-edit mr-1" />
							</span>
                        }
					</td>
				</tr>});
		}
		this.setState({
			tableRows: listeners,
            pageCount: result.data.pageCount,
			showLoading: false
		})
	}

	openCloseFilterModal(value) {
		this.setState({
			filterModalOpen: value
		})
	}

	openGenerateCertModal(listener) {
		this.setState({
			selectedListener: listener,
			generateCertModal: true
		})
	}

	closeGenerateCertModal() {
		this.setState({
			selectedListener: {},
			generateCertModal: false
		})
	}

	inputAmount(listener) {
		this.setState({
			listener: listener,
			modalOpen: true
		});
	}

	openChangeGroupModal(listener) {
		this.setState({
			listener: listener,
			changeGroupModalOpen: true
		});
	}

	revertCertificate(listener) {
		axios.post(apiHost + '/api/listener/revertCertificate?listenerId=' + listener.id, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

    removeListener(listener) {
        Swal.fire({
            title: 'Siz haqiqatdan ham "' + listener.lastName + ' ' + listener.firstName + ' ' + listener.middleName + "\" ma'lumotini o‘chirmoqchimisiz?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'O‘chirish',
            cancelButtonText: 'Bekor qilish'
        }).then((result1) => {
            if (result1.value) {
                axios.post(apiHost + '/api/listener/removeListener?listenerId=' + listener.id, null, authHeader())
                    .then(result => {
                        if (result.status === 200) {
                            Swal.fire(
                                'O‘chirildi!',
                                result.data.message,
                                'success'
                            );
                            this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
                        } else {
                            Swal.fire(
                                'Xatolik',
                                result.data.message,
                                'error'
                            )
                        }
                    }).catch(error=>{
					if (error !== undefined && error !== null && error.response.status === 401) {
						logout();
					}
				});
            } else if (result1.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Bekor qilindi',
                    '',
                    'error'
                )
            }
        });
    }

	changeStatus(listener) {
		axios.post(apiHost + '/api/listener/leaveFromGroup?listenerId=' + listener.id, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	generateCertificate(listenerId) {
		axios.post(apiHost + '/api/listener/generateCertificate?listenerId=' + listenerId, null, authHeader())
			.then(result => {
				if (result.status === 200) {
					this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
					swal(result.data.message, "", "success");
				} else if (result.status === 500) {
					swal(result.data.message, "", "error");
				}
			}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	generateMediatorCertificate() {
		let certificateNumber = document.getElementById('certificateNumber').value;
		let registerNumber = document.getElementById('registerNumber2').value;
		axios.post(apiHost + '/api/listener/generateMediatorCertificate?listenerId=' + this.state.selectedListener.id +
			'&certificateNumber=' + certificateNumber + '&registerNumber=' + registerNumber, null, authHeader()).then(result => {
			if (result.status === 200) {
				this.fetchData(this.state.status, this.state.paymentStatus, this.state.category, this.state.groupId, this.state.regionId, this.state.start, this.state.offset);
				swal(result.data.message, "", "success");
			} else if (result.status === 500) {
				swal(result.data.message, "", "error");
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	onCloseModal(modalName) {
		this.setState({
			[modalName]: false
		})
	}

	onChangeValue() {
		let searchKey = document.getElementById('searchBox').value;
		this.setState({
			searchKey: searchKey
		})
	}

	search() {
		if (this.state.searchKey !== '') {
			this.setState({
				showLoading: true
			});
			axios.get(apiHost + '/api/listener/search?keyword=' + this.state.searchKey, authHeader())
				.then(result => {
					this.populateData(result);
				}).catch(error=>{
				if (error !== undefined && error !== null && error.response.status === 401) {
					logout();
				}
			})
		}
	}

	exportToExcel() {
		let statusId = document.getElementById('status').value;
		let paymentStatusId = document.getElementById('paymentStatus').value;
		let categoryId = document.getElementById('category').value;

		let data = {
			"statusId": statusId,
			"categoryId": categoryId,
			"groupId": this.state.groupId,
			"paymentStatusId": paymentStatusId,
			"listenerType": this.state.listenerType
		}

		axios.post(apiHost + "/api/reports/listener/list/forAccounting", data, blobAuthHeader())
			.then(result => {
				if (result.status === 200) {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([result.data]));
					link.setAttribute('download', 'Hisobot.xlsx');

					document.body.appendChild(link);

					link.click();
					link.parentNode.removeChild(link);
				}
			})
	}

	setDocDate(docDate) {
		this.setState({
			docDate: docDate
		})
	}

	setOrderDate(orderDate) {
		this.setState({
			orderDate: orderDate
		})
	}

	render() {
		pageNotFound();
		return(
			<div className="content">
				{this.state.showLoading &&
				<div className="container">
					<div className="loader">
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--dot"/>
						<div className="loader--text"/>
					</div>
				</div>
				}
				<div className="card card-body shadow-sm">
					<div className="form-row">
						<div className="col-md-2">
							<div className="input-group">
								<button className="btn btn-info mr-1" onClick={this.addListener.bind(this)}>
									<i className="fa fa-plus-square-o"/></button>
								<button className="btn btn-primary"
										onClick={this.openCloseFilterModal.bind(this)}>
									<i className="fa fa-filter"/></button>
							</div>
						</div>
						<div className="col-md-7 text-center">
							<h3>Tinglovchilar ro‘yxati</h3>
						</div>
						<div className="col-md-3">
							<div className="input-group float-right">
								<input className="form-control" id="searchBox" placeholder="Qidiruv so‘zi" aria-label="Qidirish" aria-describedby="basic-addon2" onChange={this.onChangeValue.bind(this)}/>
								<div className="input-group-append">
									<button className="btn btn-outline-info" type="button" onClick={this.search.bind(this)}><i className="fa fa-search"/></button>
								</div>
							</div>
						</div>
					</div>
					<div className="form-row pt-1">
						<div className="col-md-12 order-md-1">
							<table className="table table-striped table-bordered shadow">
								<thead>
								<tr>
									<th style={{width: "5%"}}>№</th>
									<th>F.I.Sh</th>
									<th>Pasport raqami</th>
									<th>Telefon raqami</th>
									<th>Yo‘nalishi</th>
									<th>Guruh raqami</th>
									<th>Qayd raqami</th>
									<th>Sertifikat raqami</th>
                                    {this.state.listenerType === 'paid' ? <th>Shartnoma raqami</th> : ''}
									<th>Statusi</th>
                                    {this.state.listenerType === 'paid' ? <th>To‘lov statusi</th> : ''}
									<th>Amal</th>
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
						<div className="col-md-12 order-md-1 text-center pt-2">
							<ReactPaginate
								previousLabel={'<<'}
								nextLabel={'>>'}
								breakLabel={'...'}
								breakClassName={'break-me'}
								pageCount={this.state.pageCount}
								marginPagesDisplayed={1}
								pageRangeDisplayed={5}
								onPageChange={this.handlePageClick}
								containerClassName={'pagination'}
								subContainerClassName={'pagination'}
								activeClassName={'active'}
							/>
						</div>
					</div>

					<Modal open={this.state.filterModalOpen} onClose={this.openCloseFilterModal.bind(this, false)}
						   center>
						<div className="container-md pt-4">
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="category">Kategoriya</label>
									<select id="category" className="form-control" value={this.state.category} onChange={this.setValue}>
										{getTopics()}
									</select>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12">
									<label>Yo`nalishlar</label>
									<LmsAutocomplete items = {this.state.tendencies} selectedValue = {this.state.tendency}
													 selectedId = {this.state.tendencyId} idParam="tendencyId" nameParam="tendency"
													 selectItem={this.setSelectedItem.bind(this)} clearSelected={this.clearSelected.bind(this)}/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12">
									<label>Guruhlar</label>
									<LmsAutocomplete items = {this.state.groupList} selectedValue = {this.state.group}
													 selectedId = {this.state.groupId} idParam="groupId" nameParam="group"
													 selectItem={this.setSelectedItem.bind(this)} clearSelected={this.clearSelected.bind(this)}/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12">
									<label htmlFor="regions">Hudud</label>
									<select id="regions" className="form-control" value={this.state.regionId}
											onChange={this.setValue}>
										{_.getRegions()}
									</select>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="status">Statusi</label>
									<select id="status" className="form-control" onChange={this.setValue}>
										<option value={0}>Hammasi</option>
										<option value={1}>Hujjat topshirgan</option>
										<option value={10}>Guruhga qo‘shildi</option>
										<option value={2}>O‘quv jarayonida</option>
										<option value={3}>Imtihon jarayonida</option>
										<option value={4}>Kutish jarayonida</option>
										<option value={5}>Kursni yakunladi</option>
										<option value={6}>Sertifikat olgan</option>
										<option value={7}>Uzrli sababga ko‘ra kelmadi</option>
										<option value={8}>Uzrsiz sababga ko‘ra kelmadi</option>
										<option value={9}>O‘qishdan chetlatildi</option>
										<option value={11}>Pul to`ladi</option>
										<option value={12}>Tinglovchilar safidan chiqarildi</option>
									</select>
								</div>
								<div className="col-md-6">
									<label htmlFor="paymentStatus">To`lov foizi</label>
									<select id="paymentStatus" className="form-control" value={this.state.statusId}
											onChange={this.setValue}>
										<option value="0">Hammasi</option>
										<option value="1">To`lagan</option>
										<option value="2">Qisman to`lagan</option>
										<option value="3">To`lamagan</option>
									</select>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.filterData.bind(this)}>
										<i className="fa fa-filter"/>
									</button>
									<button className="ml-1 btn btn-info" style={{height: "42px"}}
											onClick={this.exportToExcel.bind(this)}><i
										className="fa fa-file-excel-o"/>
									</button>
								</div>
							</div>
						</div>
					</Modal>

					<Modal open={this.state.generateCertModal} onClose={this.closeGenerateCertModal.bind(this)} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<h4>Tinglovchi: {this.state.selectedListener.lastName + ' ' + this.state.selectedListener.firstName + ' ' + this.state.selectedListener.middleName}</h4>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="certificateNumber">Sertifikat raqami</label>
								</div>
								<div className="col-md-6">
								<input className="form-control" id="certificateNumber"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-6">
									<label htmlFor="registerNumber2">Qayd raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="registerNumber2"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.generateMediatorCertificate.bind(this)}>Sertifikat berish</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.infoDocModal} onClose={this.hideInfoDocModal.bind(this)} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="docDate">Berilgan vaqti</label>
								</div>
								<div className="col-md-6">
									<DatePicker className="form-control" id="docDate" locale="uz" selected={this.state.docDate}
												dateFormat="dd.MM.yyyy" onChange={this.setDocDate.bind(this)} showMonthYearDropdown/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="registerNumber">Qayd raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="registerNumber"/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="orderDate">Buyruq sanasi</label>
								</div>
								<div className="col-md-6">
									<DatePicker className="form-control" id="orderDate" locale="uz" selected={this.state.orderDate}
												dateFormat="dd.MM.yyyy" onChange={this.setOrderDate.bind(this)} showMonthYearDropdown/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<label htmlFor="orderNumber">Buyruq raqami</label>
								</div>
								<div className="col-md-6">
									<input className="form-control" id="orderNumber"/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.getInfoDoc.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this, 'modalOpen')} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="payedAmount">To‘lov miqdori</label>
									<input type="number" className="form-control" id="payedAmount" onChange={this.setValue}/>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.enterPayedAmount.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={this.state.changeGroupModalOpen} onClose={this.onCloseModal.bind(this, 'changeGroupModalOpen')} center>
						<div style={{padding: "20px", minWidth: "200px"}}>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="listenerName">Tinglovchi</label>
									<input className="form-control" disabled={true} id="listenerName"
                                           value={this.state.listener !== null ? this.state.listener.lastName + ' ' + this.state.listener.firstName + ' ' +  this.state.listener.middleName : ''}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="oldGroup">Avvalgi guruhi</label>
									<input className="form-control" disabled={true} id="oldGroup" value={this.state.listener !== null ? this.state.listener.group.name : ''}/>
								</div>
							</div>
							<div className="form-row">
								<div className="col-md-12">
									<label htmlFor="group">Yangi guruhi</label>
									<select className="form-control" id="group" onChange={this.setValue}>
										{this.state.groups}
									</select>
								</div>
							</div>
							<div className="form-row mt-2">
								<div className="col-md-12 text-center">
									<button className="btn btn-success" onClick={this.changeGroup.bind(this)}>Saqlash</button>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		)
	}
}