import React, {Component} from 'react';
import {apiHost, topics} from "./Utils";
import axios from "axios";
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import authHeader from "./services/auth-header";

export default class ListenerHomeView extends Component {

    constructor() {
        super();
        this.state = {
            tendencies: [],
            modalOpen: false,
            topicId: 1
        }
    }

    onSelectTendency(topicId) {
        localStorage.setItem('topicId', topicId);
        window.open("/addAgreement", "_self");
    }

    onOpenModal(topicId) {
        axios.get(apiHost + '/api/agreement/topicTendencies?id=' + topicId, authHeader()).then(result => {
            if (result.status === 200) {
                if (result.data !== undefined && result.data !== null) {
                    let items = result.data.map(item => {
                        return <li key={item.id}><span className="linkStyle"
                                                       onClick={this.onSelectTendency.bind(this, item.id)}>{item.name}</span>
                        </li>
                    });
                    this.setState({
                        tendencies: items,
                    });
                }
            } else {
                this.setState({
                    tendencies: []
                });
            }
        });
        this.setState({
            topicId: topicId,
            modalOpen: true
        })
    }

    onCloseModal(modalName) {
        this.setState({
            [modalName]: false
        })
    }

    render() {
        return (
            <div className="container-sm">
                <div className="form-row" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <div className="col-md-12 mt-3 mb-3 text-center">
                        <span style={{fontSize: "36px", fontWeight: "bold"}}>Shartnoma tuzish</span>
                    </div>
                </div>
                <div style={{margin: "auto", width: "60%"}}>
                    <div className="form-row">
                        <div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 1)}>
                            <div className="topic-name boxName">
                                <img src="/images/mediatsiya.png" alt={topics[0]} className="w-100"/>
                                <span>{topics[0]}</span>
                            </div>
                        </div>

                        <div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 4)}>
                            <div className="topic-name boxName">
                                <img src="/images/qmkurs.png" alt={topics[3]} className="w-100"/>
                                <span>{topics[3]}</span>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 3)}>
                            <div className="topic-name boxName">
                                <img src="/images/moshirish.png" alt={topics[2]} className="w-100"/>
                                <span>{topics[2]}</span>
                            </div>
                        </div>

                        <div className="col-md-6 text-center p-1" onClick={this.onSelectTendency.bind(this, 2)}>
                            <div className="topic-name boxName">
                                <img src="/images/qtayyorlash.png" alt={topics[1]} className="w-100"/>
                                <span>{topics[1]}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.modalOpen} onClose={this.onCloseModal.bind(this, 'modalOpen')} center>
                    <div style={{padding: "20px", minWidth: "200px"}}>
                        <div className="form-row">
                            <div className="col-md-12">
                                <h4>{topics[this.state.topicId - 1]}</h4>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-md-12">
                                <ul>
                                    {this.state.tendencies}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}