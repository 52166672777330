import React, {Component} from 'react';
import {isMainController, logout, pageNotFound, openPage} from './Utils';

export default class MainControllerHomeView extends Component {

	render() {
		pageNotFound();
		if (!isMainController()) {
			logout();
		}

		return (
			<div className="container-sm">
				<div style={{margin: "auto"}}>
					<div className="form-row">
						<div className="col-md-4 text-center p-1" onClick={() => openPage('/agreements')}>
							<div className="topic-name boxName">
								<img src="/images/agreements.jpg" alt='Shartnomalar ro`yxati'/>
								<span>Shartnomalar</span>
							</div>
						</div>
						<div className="col-md-4 text-center p-1"
							 onClick={() => openPage('/tendencies?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/tendencies.jpg" alt='Yo`nalishlar ro`yxati'/>
								<span>Yo`nalishlar</span>
							</div>
						</div>
						<div className="col-md-4 text-center p-1"
							 onClick={() => openPage('/groups?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/groups.jpg" alt='Guruhlar ro`yxati'/>
								<span>Guruhlar</span>
							</div>
						</div>
					</div>

					<div className="form-row">
						<div className="col-md-4 text-center p-1"
							 onClick={() => openPage('/listeners?type=paid')}>
							<div className="topic-name boxName">
								<img src="/images/listeners.jpg" alt='Tinglovchilar ro`yxati'/>
								<span>Tinglovchilar</span>
							</div>
						</div>
						<div className="col-md-4 text-center p-1"
							 onClick={() => openPage('/creport')}>
							<div className="topic-name boxName">
								<img src="/images/reports.png" alt='Hisobotlar'/>
								<span>Hisobotlar</span>
							</div>
						</div>

						<div className="col-md-4 text-center p-1"
							 onClick={() => openPage('/settings')}>
							<div className="topic-name boxName">
								<img src="/images/settings.png" alt='Sozlamalar'/>
								<span>Sozlamalar</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}