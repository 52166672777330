import React, {Component} from 'react';
import axios from 'axios';
import * as _ from './Utils';
import {Link} from "react-router-dom";
import swal from "sweetalert";
import authHeader from "./services/auth-header";
import {isController, isMainController, logout, pageNotFound} from "./Utils";

class TendencyList extends Component {

	constructor() {
		super();
		let user = localStorage.getItem('user');
		if (user !== undefined && user !== null) {
			user = JSON.parse(user);
		}
		this.state = {
			user: user,
			tableRows: [],
			searchKeyword: ''
		};
	}

	componentDidMount() {
        this.getData();
	}

	getData() {
		let urlParams = new URLSearchParams(window.location.search);
		let tendencyType = urlParams.get('type');
		let user = JSON.parse(localStorage.getItem('user'));
		axios.get(_.apiHost + '/api/admin/tendency/list?type=' + tendencyType, authHeader()).then(result => {
			if (result.status === 200) {
					let options = result.data.map(row=> {
						return <tr key={row.id}>
							<td className="align-middle">{row.id}</td>
							<td className="align-middle">
								<Link to={"/tendencyView?id=" + row.id} className="linkStyle">{row.name}</Link>
							</td>
							<td className="align-middle">{row.price}</td>
							<td className="align-middle">{row.topic !== null ? row.topic.name : ''}</td>
							<td className="align-middle d-flex">
								{user.roles[0] === 'MARKETING' &&
								<a href={"/editAgreement?id=" + row.id} className="c-btn"
									  title="Shartnoma qo‘shish"><span><i className="fa fa-plus-square-o mr-1"/></span></a>
								}
								{!(isController() || isMainController()) &&
								<>
									<a href={"/addTendency?id=" + row.id} className="c-btn" title="Tahrirlash">
										<span><i className="fa fa-edit"/></span></a>
									<span className="c-btn" title="O‘chirish" onClick={this.removeTendency.bind(this, row.id)}>
										<i className="fa fa-remove"/>
									</span>
								</>
								}
							</td>
						</tr>;
					});
					this.setState({
						tableRows: options
					})
			} else {
				this.setState({
					tableRows: []
				})
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response.status === 401) {
				logout();
			}
		})
	}

	removeTendency(tendencyId) {
		axios.delete(_.apiHost + '/api/admin/tendency/remove?id=' + tendencyId, authHeader()).then(result=>{
			console.log(result);
			if (result.status === 200) {
				swal(result.data.message, '', 'success');
				this.getData();
			} else {
				swal(result.data.message, '', 'error');
			}
		}).catch(error=>{
			if (error !== undefined && error !== null && error.response !== null) {
				if (error.response.status === 401) {
					logout();
				} else {
					swal(error.response.data.message, '', 'error');
				}
			}
		})
	}

	render() {
		pageNotFound();
		let user = JSON.parse(localStorage.getItem('user'));
		return (
			<div className="content">
				<div className="card card-body shadow-sm">
					<div className="row">
						<div className="col-md-12">
							<div className="row">
								<div className="col-md-6 text-right">
									<h3>Yo‘nalishlar ro‘yxati</h3>
								</div>
								<div className="col-md-6 text-right">
									<Link className="btn btn-info" to="/addTendency">Yo‘nalish qo‘shish</Link>
								</div>
							</div>
						</div>
						<div className="col-md-12 pt-2">
							<table className="table table-striped table-bordered shadow">
								<thead>
								<tr>
									<th scope="col" className="align-middle">№</th>
									<th scope="col" className="align-middle">Yo‘nalish nomi</th>
									<th scope="col" className="align-middle">Narxi</th>
									<th scope="col" className="align-middle" style={{minWidth: "200px"}}>Turi</th>
									{!(user.roles[0] === 'CONTROLLER' || user.roles[0] === 'MAIN_CONTROLLER') &&
									<th scope="col" className="align-middle">Amal</th>
									}
								</tr>
								</thead>
								<tbody>
								{this.state.tableRows}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default TendencyList;