import React, {Component} from 'react';
import Moment from "react-moment";
import {apiHost, logout, pageNotFound} from "./Utils";
import swal from 'sweetalert';
import axios from 'axios';
import authHeader from "./services/auth-header";

export default class LatePaymentsListView extends Component {

    constructor() {
        super();
        this.state = {
            tableRows: []
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        axios.get(apiHost + '/api/listener/latePayment/list', authHeader())
            .then(result => {
            if (result.status === 200) {
                if (result.data !== null) {
                    let rows = [];
                    rows = result.data.map((row, index) => {
                        return <tr>
                            <td>{index + 1}</td>
                            <td>{row.lastName + ' ' + row.firstName + ' ' + row.middleName}</td>
                            <td>{row.group.name}</td>
                            <td>{row.agreement.name}</td>
                            <td>{row.allAmount}</td>
                            <td>{row.payedAmount}</td>
                            <td>{row.startDate !== null ? <Moment format="DD.MM.YYYY">{row.startDate}</Moment> : ''}</td>
                            <td>{row.endDate !== null ? <Moment format="DD.MM.YYYY">{row.endDate}</Moment> : ''}</td>
                            <td className="text-center">
                                <button className="btn btn-success" title="Sertifikat berish" onClick={this.generateCertificate.bind(this, row.id)}>
                                    <i className="fa fa-credit-card"/></button>
                            </td>
                        </tr>
                    });
                    this.setState({
                        tableRows: rows
                    })
                }
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        });
    }

    generateCertificate(listenerId) {
        axios.post(apiHost + '/api/listener/generateCertificate3?listenerId=' + listenerId, null, authHeader()).then(result => {
            if (result.status === 200) {
                this.loadData();
                swal(result.data.message, "", "success");
            } else if (result.status === 500) {
                swal(result.data.message, "", "error");
            }
        }).catch(error=>{
            if (error !== undefined && error !== null && error.response.status === 401) {
                logout();
            }
        })
    }

    render() {
        pageNotFound();
        return(
            <div className="content">
                <div className="card card-body mt-1 shadow-sm">
                    <div className="form-row">
                        <div className="col-md-12">
                            <table className="table table-striped table-bordered shadow" style={{tableLayout: "fixed"}}>
                                <thead>
                                <tr>
                                    <th style={{width: "5%"}}>№</th>
                                    <th>Tinglovchining F.I.Sh</th>
                                    <th>Guruhi</th>
                                    <th>Shartnoma raqami</th>
                                    <th>Jami summa</th>
                                    <th>To‘langan summa</th>
                                    <th>O`qish tugagan vaqti</th>
                                    <th>Pul to‘langan vaqti</th>
                                    <th width="8%">Amal</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.tableRows}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}